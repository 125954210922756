<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
        lg="6"
        md="12"
      />
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
        <analytics-avg-sessions :data="data" />
      </b-col>
    </b-row>
    <b-row>
      <b-col size="12">
        <analitycs-pits-reading />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'
import AnalitycsPitsReading from '@/views/dashboards/AnalitycsPItsReading.vue'
import AnalyticsAvgSessions from './AnalyticsAvgSessions.vue'

export default {
  components: {
    AnalitycsPitsReading,
    BRow,
    BCol,
    AnalyticsAvgSessions,
  },
  data() {
    return {
      data: {
        pits: {
          total: 0,
          with_device: 0,
          percentual: 0,
        },
        devices: {
          total: 0,
          in_use: 0,
          percentual: 0,
        },
      },
    }
  },
  async created() {
    // data
    this.data = await this.$http.get('/dashboards/resume')
  },
  methods: {
    kFormatter,
  },
}
</script>
