<template>
  <b-card
    style="width: 100%"
    title="Data da última leitura registrada para os poços cadastrados"
  >
    <grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
    >
      <template #cell(unity)="data">
        {{ data.item.unity.name }} ({{ data.item.unity.company.name }})
      </template>
      <template #cell(last_read)="data">
        {{ data.item.last_read | moment("DD/MM/YYYY") }}
      </template>
    </grid>
  </b-card>
</template>
<script>
import Grid from '@/components/i2/grid/Grid.vue'

export default {
  name: 'AnalitycsPitsReading',
  components: {
    Grid,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Poço',
          class: 'w-1',
        },
        {
          key: 'unity',
          label: 'Unidade',
          class: 'w-2',
        },

        {
          key: 'last_read',
          label: 'Última leitura',
          class: 'w-3',
        },
      ],
      items: [],
    }
  },
  async created() {
    // data
    this.items = await this.$http.get('/dashboards/pits-without-reading')
  },
}
</script>

<style scoped>

</style>
