<template>
  <b-card
    v-if="data"
    style="width: 100%"
  >
    <b-row class="avg-sessions">
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-card-text class="">
          <b>Total de poços</b>: {{ data.pits.total }}
          <br>
          <b>Poços com dispositivo:</b> {{ data.pits.with_device }}
        </b-card-text>
        <b-progress
          :value="data.pits.percentual"
          max="100"
          height="6px"
        />
      </b-col>
      <b-col
        v-if="user.type !== 3"
        cols="12"
        class="mb-2"
      >
        <b-card-text class="mb-50">
          <b>Dispositivos:</b> {{ kFormatter(data.devices.total) }}
          <br>
          <b>Dispositivo em Uso:</b> {{ data.devices.in_use }}
        </b-card-text>
        <b-progress
          :value="data.devices.percentual"
          max="100"
          height="6px"
          variant="success"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BProgress, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCardText,
    BProgress,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        pits: {
          total: 0,
          with_device: 0,
          percentual: 0,
        },
        devices: {
          total: 0,
          in_use: 0,
          percentual: 0,
        },
      }),
    },
  },
  data() {
    return {
      user: {
        type: 1,
      },
    }
  },
  mounted() {
    this.user = getUserData()
  },
  methods: {
    kFormatter,
  },
}
</script>
